<template>
  <div class="d-block" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <div
      class="d-inline-flex w-100 yedi-filter-control justify-content-between pl-0"
    >
      <div
        class="w-100"
        :class="
          conditionIsValid === true
            ? 'filter-is-valid'
            : conditionIsValid === false
            ? 'filter-is-invalid'
            : ''
        "
      >
        <autosize-input
          ref="field"
          :value="selectedField"
          input-class="yedi-filter-input ml-3 cursor-pointer"
          :placeholder="$t('mapping.selectField')"
          readonly
          @focus="onFieldFocus"
          @blur="onFieldBlur"
        />
        <autosize-input
          v-if="
            (condition.field && condition.field.length > 4) ||
            condition.operator
          "
          ref="operator"
          :value="condition.operator"
          input-class="yedi-filter-input filter-operator mx-1 cursor-pointer"
          :placeholder="$t('mapping.selectOperator')"
          readonly
          @focus="onOperatorFocus"
          @blur="onOperatorBlur"
        />
        <autosize-input
          v-if="condition.operator"
          ref="value"
          v-model="conditionValue"
          input-class="yedi-filter-input filter-value"
          :placeholder="$t('mapping.selectValue')"
          :readonly="readOnly"
          @focus="onValueFocus"
          @blur="onValueBlur"
          @change="onValueChange"
        />
        <span
          v-if="condition.operator && conditionValueType"
          class="text-muted font-size-xs"
        >
          {{ "(" + conditionValueType + ")" }}
        </span>
      </div>
      <div v-if="!readOnly" class="d-flex">
        <button
          v-b-tooltip.top.noninteractive="$t('mapping.clearConditionHelp')"
          class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary yedi-filter-input-button"
          @click="clearFilter()"
        >
          <i class="fal fa-delete-left icon-lg pr-0" />
        </button>
        <button
          v-b-tooltip.top.noninteractive="$t('mapping.removeConditionHelp')"
          class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary yedi-filter-input-button"
          @click="removeCondition()"
        >
          <i class="fal fa-trash-can icon-lg pr-0" />
        </button>
      </div>

      <button
        v-if="conditionGroup.children.length > 1"
        class="btn btn-sm btn-clean btn-circle btn-hover-icon-primary yedi-filter-input-button"
        style="padding-left: 0.55rem; padding-right: 0.55rem; min-width: 55px"
        @click="addGroup"
      >
        {{ $t("mapping.addConditionGroup") }}
      </button>

      <!-- Field menu -->
      <v-menu
        v-model="fieldMenu.shown"
        z-index="2000"
        :position-x="fieldMenu.x"
        :position-y="fieldMenu.y"
        :open-on-click="false"
        :close-on-click="false"
        :max-height="500"
      >
        <v-list class="p-0" style="min-width: 200px">
          <template v-if="!fields.length">
            <v-list-item dense :disabled="true">
              <v-list-item-title>
                {{ $t("mapping.conditionsNoFields") }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-for="(field, i) in fields">
            <v-divider v-show="i !== 0" :key="`${i}-divider`" class="my-0" />
            <v-list-item
              :key="`${i}-a`"
              class="field-data"
              dense
              @click="selectField(field.full_name)"
            >
              <v-list-item-title>
                {{ field.full_name }}
                <span v-if="field.type" class="text-muted">
                  {{ " - " }}<em>{{ field.type }}</em>
                </span>
                <span v-if="field.value" class="text-muted">
                  {{ " - " + field.value }}
                </span>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <!-- Field menu -->

      <!-- Operator menu -->
      <v-menu
        v-model="operatorMenu.shown"
        z-index="2000"
        :position-x="operatorMenu.x"
        :position-y="operatorMenu.y"
        :open-on-click="false"
        :close-on-click="false"
      >
        <v-list class="p-0">
          <v-list-item
            v-for="operator in defaultOperators"
            :key="operator"
            class="operator-data"
            dense
            @click="selectOperator(operator)"
          >
            <v-list-item-title>{{ operator }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- Operator menu -->
    </div>
  </div>
</template>

<script>
import AutosizeInput from "vue-autosize-input";
import defaultOperators from "@/components/Projects/Mappings/Conditions/defaultOperators.json";
import { mapGetters } from "vuex";
import { typeCast } from "@/components/Projects/Mappings/helpers";

export default {
  name: "ConditionChild",
  components: {
    AutosizeInput
  },
  props: {
    condition: {
      type: Object,
      default: null
    },
    conditionGroup: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      operators: defaultOperators.defaultOperators,
      conditionFieldSearchTerm: "",
      conditionIsValid: undefined,
      showAddButton: false,
      fieldMenu: {
        shown: false,
        x: 0,
        y: 0
      },
      operatorMenu: {
        shown: false,
        x: 0,
        y: 0
      },
      lastEvent: undefined,
      isValueFocus: false,
      // based on php comparison operators
      defaultOperators: defaultOperators.defaultOperators
    };
  },
  computed: {
    ...mapGetters("mapping", ["sourceFields", "configValues"]),
    ...mapGetters("mappingTransformer", [
      "inputVars",
      "outputVars",
      "selectedTransformer"
    ]),
    selectedField: {
      get: function () {
        return this.condition.field;
      },
      set: function (value) {
        this.condition.field = `{{${value}}}`;
      }
    },
    conditionValue: {
      get: function () {
        return this.condition.value !== undefined
          ? String(this.condition.value)
          : "";
      },
      set: function (value) {
        this.condition.value = typeCast(value);
      }
    },
    conditionValueType: function () {
      let value = this.condition.value;
      if (value === undefined || value === "") return "";
      let type = typeof value;
      if (type === "string" && value.startsWith("{{") && value.endsWith("}}")) {
        type = "variable";
      } else if (value === null) {
        type = "null";
      }
      type = type.charAt(0).toUpperCase() + type.slice(1);
      type = this.$t("mapping.type" + type);
      return type;
    },
    selectedOperator: function () {
      return this.defaultOperators.find(o => o === this.condition.operator);
    }
  },
  mounted() {
    this.validateCondition();
  },
  methods: {
    showMenu(e, menu) {
      if (this.readOnly) return;

      this.lastEvent = e;
      const offset = e.target.getBoundingClientRect();

      menu.x = offset.x - 4;
      menu.y = offset.y + offset.height;
      menu.shown = true;
    },
    hideMenu(menu) {
      menu.shown = false;
    },
    selectField(field) {
      this.isValueFocus
        ? this.selectValueField(field)
        : this.selectFieldField(field);
    },
    selectFieldField(field) {
      this.selectedField = field;
      this.validateCondition();
      if (this.condition.operator?.length === 0) {
        this.$nextTick().then(() => {
          setTimeout(() => this.$refs.operator.focus(), 100);
        });
        return;
      }
      if (this.condition.value?.length === 0) {
        this.$nextTick().then(() => {
          setTimeout(() => this.$refs.value.focus(), 100);
        });
      }
    },
    selectValueField(name) {
      this.conditionValue = `{{${name}}}`;
      this.$refs.value.focus();
    },
    selectOperator(name) {
      this.condition.operator = name;
      this.validateCondition();
    },
    validateCondition() {
      this.condition.valid =
        this.selectedField?.length > 4 &&
        !!this.selectedOperator &&
        this.conditionValue.trim().length > 0;

      if (
        !this.condition.field &&
        !this.condition.operator &&
        !this.condition.value
      ) {
        this.conditionIsValid = undefined;
        this.condition.valid = undefined;
      } else {
        this.conditionIsValid = this.condition.valid;
      }

      this.$emit("validate");
    },

    clearFilter() {
      this.conditionFieldSearchTerm = "";
      this.condition.field = "";
      this.condition.operator = "";
      this.condition.value = "";
      this.validateCondition();
      this.$nextTick().then(() => {
        this.$refs.field.focus();
      });
    },
    removeCondition() {
      this.$emit("removeCondition");
    },

    onMouseEnter() {
      this.showAddButton = true;
    },
    onMouseLeave() {
      this.showAddButton = false;
    },
    addGroup() {
      this.$emit("addGroup");
    },
    onFieldFocus(e) {
      this.isValueFocus = false;
      this.showMenu(e, this.fieldMenu);
    },
    onFieldBlur(e) {
      if (e.relatedTarget.classList.contains("v-list-item")) {
        return;
      }
      this.hideMenu(this.fieldMenu);
    },
    onOperatorFocus(e) {
      this.showMenu(e, this.operatorMenu);
    },
    onOperatorBlur() {
      setTimeout(() => this.hideMenu(this.operatorMenu), 100);
    },
    onValueFocus(e) {
      this.isValueFocus = true;
      this.handleValueMenus(e);
      this.validateCondition();
    },
    onValueBlur(e) {
      if (e.relatedTarget.classList.contains("v-list-item")) {
        return;
      }
      this.hideMenu(this.fieldMenu);
    },
    onValueChange(e) {
      this.handleValueMenus(e);
      this.validateCondition();
    },
    handleValueMenus(e) {
      let value = this.conditionValue;
      if (!value.startsWith("{{")) {
        this.fieldMenu.shown = false;
      } else {
        this.showMenu(e, this.fieldMenu);
      }
    }
  }
};
</script>

<style lang="scss">
.filter-operator {
  color: rgb(128, 95, 150) !important;
}

.filter-value {
  color: rgb(60, 105, 35) !important;
}

.filter-is-invalid {
  outline: 1px solid rgb(227, 91, 100);
  border-top-left-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
  z-index: 1;

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.filter-is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231BC5BD' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}

.yedi-filter-input-button {
  border-left: 1px solid #e4e6ef !important;
  border-radius: 0 !important;
  background-color: #f3f6f9 !important;
}

.yedi-filter-control {
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
}

.yedi-filter-input {
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 0 8px;
}

.yedi-filter-input:focus {
  outline: none;
}
</style>
